import AppDownload from "../footer/appDownload";
import styles from "./errorBody.module.css";
import heart from "../../assets/heart.png";
import languages from "../../languages/translate";
import { useContext } from "react";
import SiteContext from "../../store/site-context";

const ErrorBody = () => {
  const { userLanguage } = useContext(SiteContext);

  return (
    <div className={`${styles.error} sitePadding main`}>
      <div>
        <img src={heart} alt="heart" className={styles.image} />
        <h2 className={styles.header}>{languages[userLanguage].error.title}</h2>
        <p className={styles.text}>{languages[userLanguage].error.description}</p>
        <a href="https://riesimebyvanie.sk/" className={styles.link}>
          riesimebyvanie.sk
        </a>
      </div>
      <div className={styles.right}>
        <AppDownload />
      </div>
    </div>
  );
};

export default ErrorBody;
