import styles from "./hero.module.css";
import useFileUrl from "../../utils/useFileUrl";

const Hero: React.FC<{ medias: { id: number; type: string; preview: boolean; url: string }[] }> = (props) => {
  const heroImage = props.medias.filter((item) => item.preview === true);
  const { loading, url, error } = useFileUrl(heroImage[0].url);
  const noImageUrl = "/imageNotAvailable.jpg";

  return (
    <>
      {loading ? (
        <div >
          <img src={noImageUrl} alt="hero" className={styles.image} />
        </div>
      ) : (
        <div className={error !== undefined ? styles.noImage : undefined}>
          {error === undefined && <img src={url} alt="hero" className={styles.image} />}
        </div>
      )}
    </>
  );
};

export default Hero;
