import styles from "./gallery.module.css";
import LightGallery from "lightgallery/react";
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";
import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgZoom from "lightgallery/plugins/zoom";
import languages from "../../languages/translate";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import SiteContext from "../../store/site-context";
import MediaComponent from "../../components/mediaComponent";

const Gallery: React.FC<{ galleryData: { id: number; url: string }[] }> = (props) => {
  const { userLanguage } = useContext(SiteContext);
  const lightGallery = useRef<any>(null);
  const [loadingState, setLoadingState] = useState(
    props.galleryData.reduce((acc, _, index) => {
      acc[index] = true;
      return acc;
    }, {} as Record<number, boolean>)
  );

  const [childrenCount, setChildrenCount] = useState(0);

  useEffect(() => {
    lightGallery.current.refresh();
    
    const lightGalleryDiv = document.getElementsByClassName("lightGallerydiv");

    setChildrenCount(lightGalleryDiv[0].children.length);
  }, [loadingState]);

  const onInit = useCallback((detail: any) => {
    if (detail) {
      lightGallery.current = detail.instance;
    }
  }, []);

  return (
    <div className="sectionMarginBottom">
      {childrenCount > 0 && <h3 className="headerSmall">{languages[userLanguage].explore}</h3>}
      <LightGallery
        speed={500}
        plugins={[lgThumbnail, lgZoom]}
        elementClassNames={`${styles.container} lightGallerydiv`}
        onInit={onInit}
      >
        {props.galleryData.map((item, index) => (
          <MediaComponent media={item.url} index={index} key={item.id} setLoadingState={setLoadingState} />
        ))}
      </LightGallery>
    </div>
  );
};

export default Gallery;
