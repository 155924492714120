import LightGallery from "lightgallery/react";
import styles from "./video.module.css";
import youTubeIcon from "../../assets/youtube.png";
import { useContext } from "react";
import SiteContext from "../../store/site-context";
import languages from "../../languages/translate";
import lgZoom from "lightgallery/plugins/zoom";
import lgVideo from "lightgallery/plugins/video";
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";
import "lightgallery/css/lg-video.css";

const Video: React.FC<{ urlYoutube: string }> = (props) => {
  const { userLanguage } = useContext(SiteContext);
  const noImageUrl = "/imageNotAvailable.jpg";

  //getting the youtube video code to be used for img thumbnail
  const urlString = props.urlYoutube;
  const positionString = urlString.lastIndexOf('/');
  const youtubeVideoCodeFull = urlString.substring(positionString + 1);
  const youtubeVideoCode = youtubeVideoCodeFull.replace('watch?v=','')

  //splitting the youtube video url
  //this is used to check format of the url
  let urlStringSplit = urlString.split('/')
  let wrongFormat = urlStringSplit.length !== 4 ? true : false

  return (
    <div className={styles.video}>
      <h3 className="headerSmall">{languages[userLanguage].video}</h3>
      <div className={styles.videoContainer} id="siteVideo">
        <img src={youTubeIcon} alt="youtube" className={styles.youtubeIcon} />
        <LightGallery plugins={[lgZoom, lgVideo]} mode="lg-fade">
          <a href={props.urlYoutube} className="gallery-item" data-src={props.urlYoutube} key="4">
            <img
              className={styles.image}
              alt="youtube video thumbnail"
              src={wrongFormat ? noImageUrl : `http://img.youtube.com/vi/${youtubeVideoCode}/0.jpg`}
            />
          </a>
        </LightGallery>
      </div>
    </div>
  );
};

export default Video;
