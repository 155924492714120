import { useCallback } from "react";
import { initializeApp } from 'firebase/app';
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import config from "../core/config";

export const useFileStorage = () => {

  const firebaseConfig = config.firebase;
  const app = initializeApp(firebaseConfig);
  const storage = getStorage(app)

  const getUrl = useCallback(
    (path:any) => {
      const storageRef = ref(storage, path);
      return getDownloadURL(storageRef);
    },
    [storage]
  );

  return {
    getUrl
  };
};
