import { useEffect } from "react";
import useFileUrl from "../utils/useFileUrl";
import styles from "./mediaComponent.module.css";

const MediaComponent: React.FC<{ media: string; setLoadingState: any; index: number }> = ({media, setLoadingState, index}) => {
  const { url, error, loading } = useFileUrl(media);

  const noImageUrl = "/imageNotAvailable.jpg";

  useEffect(() => {
    setLoadingState((prevState: any) => {
      return {
        ...prevState,
        [index]: loading,
      };
    });
  }, [loading, index, setLoadingState]);


  return (
    <>
      {loading ? (
        <a href={noImageUrl} className={styles.imageContainer}>
          <img src={noImageUrl} alt="" className={styles.image} />
        </a>
      ) : (
        <>
          {error === undefined && (
            <a href={url} className={styles.imageContainer}>
              <img src={url} alt="" className={styles.image} />
            </a>
          )}
        </>
      )}
    </>
  );
};

export default MediaComponent;
