import styles from "./footer.module.css";
import phone from "../../assets/phone.svg";
import email from "../../assets/mail.svg";
import AppDownload from "./appDownload";
import useWindowDimensions from "../../utils/WindowDimensions";
import { Size } from "../../types/enums";
import languages from "../../languages/translate";
import { useContext } from "react";
import SiteContext from "../../store/site-context";
import useFileUrl from "../../utils/useFileUrl";

const Footer: React.FC<{
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  agentUrl: string;
  role: string;
}> = (props) => {
  const { width } = useWindowDimensions();
  const { userLanguage } = useContext(SiteContext);
  const { url } = useFileUrl(props.agentUrl);

  return (
    <footer className={ `${styles.footer} sitePadding`}>
      
        <div className={styles.main}>
          <h3 className="headerSmall">{languages[userLanguage].broker}</h3>
          <div className={styles.imageContainer}>
            <p className={styles.tag}>pro</p>
            <img src={url} alt="avatar" className={styles.image} />
          </div>
          {(props.firstName || props.lastName) && (
            <p className={styles.name}>{`${props.firstName} ${props.lastName}`}</p>
          )}
          <p className={styles.role}>{languages[userLanguage].licence}</p>
          <div className={styles.contactBlock}>
            {props.phone && (
              <div className={`${styles.wrapper} ${styles.wrapperMargin}`}>
                <div className={styles.phoneContainer}>
                  <img src={phone} alt="phone" className={styles.icon} />
                </div>
                <p>
                  <a href={`tel:${props.phone}`}>{props.phone}</a>
                </p>
              </div>
            )}
            <div className={styles.wrapper}>
              <div className={styles.emailContainer}>
                <img src={email} alt="email" className={styles.icon} />
              </div>
              <p>
                <a href={`mailto:${props.email}`}>{props.email}</a>
              </p>
            </div>
          </div>
        </div>
      
      {width < Size.desktopBreakpoint ? <AppDownload /> : null}
    </footer>
  );
};

export default Footer;
