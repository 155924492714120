import styles from "./map.module.css";
import useWindowDimensions from "../../utils/WindowDimensions";
import { Size } from "../../types/enums";
import { useEffect, useMemo, useState } from "react";
import { GoogleMap, useLoadScript, MarkerF } from "@react-google-maps/api";
import languages from "../../languages/translate";
import {useContext} from 'react';
import SiteContext from "../../store/site-context";

const MapComponent: React.FC<{ longitude: number; latitude: number}> = (props) => {
  const longitude = props.longitude;
  const latitude = props.latitude;
  const { width } = useWindowDimensions();
  const center = useMemo(() => ({ lat: latitude, lng: longitude}), [longitude,latitude]);
  const {userLanguage} = useContext(SiteContext)

  const mapElementHeight = () => {
    if (mapHeight > 0) return mapHeight;
    else return width < Size.tabletBreakpoint ? width * 0.5 : width < Size.desktopBreakpoint ? (width / 2) * 0.5 : 180;
  };

  const [mapHeight, setMapHeight] = useState(0);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: (process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string),
  });

  const containerStyle = {
    height:mapElementHeight()
  }

  useEffect(() => {
    const videoElement = document.getElementById("siteVideop");
    const videoElementHeight: any = videoElement?.getBoundingClientRect().height;

    if (videoElement) setMapHeight(videoElementHeight);
  }, []);

  if (!isLoaded) return <div>...Loading</div>;

  return (
    <div className={styles.map}>
      <h3 className="headerSmall">{languages[userLanguage].map}</h3>
      <div className={styles.container} >
        <GoogleMap zoom={17} center={center} mapContainerStyle={containerStyle}>
          <MarkerF position={center} />
        </GoogleMap>
      </div>
    </div>
  );
};

export default MapComponent;
