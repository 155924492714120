import ErrorBody from "../modules/errorPage/errorBody";
import Header from "../modules/header/header";

const ErrorPage = () => {
  return (
    <>
      <Header />
      <ErrorBody />
    </>
  );
};

export default ErrorPage;
