import { AmenitiesComponent } from "../types/types";
import useFileUrl from "../utils/useFileUrl";
import styles from "./amenityComponent.module.css";

const AmenityComponent: React.FC<AmenitiesComponent> = (
  props
) => {
  const firebasePath = process.env.REACT_APP_FIREBASE_TOKEN_PATH
  const { url } = useFileUrl(`${firebasePath}${props.iconUrl}`);

  return (
    <div className={styles.itemContainer}>
      <img src={url} alt={props.name} />
      <p className={props.active === true ? styles.active : styles.notActive}>{props.value}</p>
    </div>
  );
};

export default AmenityComponent;
