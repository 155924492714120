import styles from "./amenities.module.css";
import languages from "../../languages/translate";
import SiteContext from "../../store/site-context";
import {useContext} from 'react'
import AmenityComponent from "../../components/amenityComponent";

const Amenities: React.FC<{ amenitiesData: { codeId: number; value: string; name: string; active: boolean; iconUrl:string }[] }> = (
  props
) => {

  const {userLanguage} = useContext(SiteContext)

  return (
    <div className="sectionMarginBottom">
      <h3 className="headerSmall">{languages[userLanguage].amenities}</h3>
      <div className={styles.container}>
        {props.amenitiesData.map((item) => (
          <AmenityComponent key={item.codeId} codeId={item.codeId} value={item.value} name={item.name} active={item.active} iconUrl={item.iconUrl}/>
        ))}
      </div>
    </div>
  );
};

export default Amenities;
