import { ISummary } from "../../types/types";
import styles from "./summary.module.css";
import languages from "../../languages/translate";
import { useContext } from "react";
import SiteContext from "../../store/site-context";

const Summary: React.FC<ISummary> = (props) => {
  const { userLanguage } = useContext(SiteContext);

  function formatDate(date: string, lang: string) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2 && userLanguage === "en") month = "0" + month;
    if (day.length < 2 && userLanguage === "en") day = "0" + day;

    if (userLanguage === "en") return [day, month, year].join("/");
    if (userLanguage === "sk") return [day, month, year].join(".");
  }

  const priceType = () => {
    if (props.priceType === "PRICE") return languages[userLanguage].priceType.price;
    if (props.priceType === "PRICE_GUIDE") return languages[userLanguage].priceType.priceGuide;
    if (props.priceType === "PRICE_UPON_REQUEST") return languages[userLanguage].priceType.priceUponRequest;
    if (props.priceType === "AUCTION") return languages[userLanguage].priceType.auction;
  };

  return (
    <div className={styles.summary}>
      {props.successStory && (
        <div className={styles.success}>
          <p className={styles.successText}>
            {props.rent ? languages[userLanguage].successStoryRent : languages[userLanguage].successStory}
          </p>
        </div>
      )}
      <div className={styles.top}>
        <div className={styles.wrapper}>
          <div className={`${styles.row} ${props.rent ? styles.rowMargin : null}`}>
            {props.rent ? (
              <p className={styles.rent}>
                <span className={styles.price}>
                  {userLanguage === "en"
                    ? `${props.price.toLocaleString("en")} €`
                    : `${props.price.toLocaleString("sk")} €`}
                </span>
                <span className={styles.month}>{`/${languages[userLanguage].month}`}</span>
              </p>
            ) : (
              <p className={styles.rent}>
                <span className={styles.priceType}>{`${priceType()} `}</span>
                {props.priceType !== "PRICE_UPON_REQUEST" && (
                  <span className={styles.price}>
                    {userLanguage === "en"
                      ? `${props.price.toLocaleString("en")} €`
                      : `${props.price.toLocaleString("sk")} €`}
                  </span>
                )}
              </p>
            )}
            {props.rent && (
              <p className={styles.text}>
                <span>{`${languages[userLanguage].availability} `}</span>
                <span className={styles.textBold}>{formatDate(props.availableFrom, userLanguage)}</span>
              </p>
            )}
          </div>
          {props.rent && (
            <div className={styles.row}>
              <p className={styles.text}>
                <span>
                  {userLanguage === "en"
                    ? `${props.deposit.toLocaleString("en")} € `
                    : `${props.deposit.toLocaleString("sk")} € `}
                </span>
                <span>{languages[userLanguage].deposit}</span>
              </p>

              <p className={styles.text}>
                <span>{`${languages[userLanguage].minimalRent} `}</span>
                <span className={styles.textBold}>{`${props.minimalRent} ${
                  props.minimalRent === 1
                    ? languages[userLanguage].months.sm
                    : props.minimalRent > 1 && props.minimalRent < 5
                    ? languages[userLanguage].months.md
                    : languages[userLanguage].months.lg
                }`}</span>
              </p>
            </div>
          )}
        </div>
      </div>
      <div className={styles.bottom}>
        <div className={styles.wrapperBottom}>
          {props.roomsInTotal && (
            <div className={styles.summaryItem}>
              <img src="assets/rooms.svg" alt="rooms" />
              <p className={styles.summaryText}>{`${props.roomsInTotal} ${
                props.roomsInTotal === 1
                  ? languages[userLanguage].rooms.sm
                  : props.roomsInTotal > 1 && props.roomsInTotal < 5
                  ? languages[userLanguage].rooms.md
                  : languages[userLanguage].rooms.lg
              }`}</p>
            </div>
          )}
          {props.numberOfBathrooms && (
            <div className={styles.summaryItem}>
              <img src="assets/baths.svg" alt="baths" />
              <p className={styles.summaryText}>{`${props.numberOfBathrooms} ${
                props.numberOfBathrooms === 1
                  ? languages[userLanguage].bath.sm
                  : props.numberOfBathrooms > 1 && props.numberOfBathrooms < 5
                  ? languages[userLanguage].bath.md
                  : languages[userLanguage].bath.lg
              }`}</p>
            </div>
          )}
          {props.area && (
            <div className={styles.summaryItem}>
              <img src="assets/area.svg" alt="area" />
              <p className={styles.summaryText}>
                {`${props.area} m`}
                <sup className={styles.superscript}>2</sup>
              </p>
            </div>
          )}
          {props.plot && props.advertisementType === 'LAND' && (
            <div className={styles.summaryItem}>
              <img src="assets/area.svg" alt="area" />
              <p className={styles.summaryText}>
                {`${props.plot} m`}
                <sup className={styles.superscript}>2</sup>
              </p>
            </div>
          )}
          {props.floor >= 0 && props.floor !== null && (
            <div className={styles.summaryItem}>
              <img src="assets/floor.svg" alt="floor" />
              <span className={styles.summaryText}>{props.floor}</span>
              <span>
                {userLanguage === "en" ? (
                  <sup className={styles.superscript}>
                    {props.floor === 1 ? "st" : props.floor === 2 ? "nd" : props.floor === 3 ? "rd" : "th"}
                  </sup>
                ) : null}
              </span>
              <span className={styles.summaryText}>{languages[userLanguage].floor}</span>
            </div>
          )}
          {props.maximalOccupancy && props.rent && (
            <div className={styles.summaryItem}>
              <img src="assets/persons.svg" alt="maximal Occupancy" />
              <p className={styles.summaryText}>{`${props.maximalOccupancy} ${
                props.maximalOccupancy === 1
                  ? languages[userLanguage].persons.sm
                  : props.maximalOccupancy > 1 && props.maximalOccupancy < 5
                  ? languages[userLanguage].persons.md
                  : languages[userLanguage].persons.lg
              }`}</p>
            </div>
          )}
          {props.petsAllowed && props.rent && (
            <div className={styles.summaryItem}>
              <img src="assets/pets.svg" alt="pets Allowed" />
              <p className={styles.summaryText}>
                {props.petsAllowed === true ? languages[userLanguage].pets.true : languages[userLanguage].pets.false}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Summary;
