import CTA from "./cta";
import styles from "./title.module.css";
import useWindowDimensions from "../../utils/WindowDimensions";
import { Size } from "../../types/enums";

const AdvTitle: React.FC<{ title: string; matterport: { url: string } }> = (props) => {
  const { width } = useWindowDimensions();

  return (
    <div className={styles.titleSection}>
      {width < Size.desktopBreakpoint && props.matterport ? (
        <div className={styles.wrapper}>
          <CTA matterport={props.matterport} />
        </div>
      ) : null}
      <h1 className={styles.title}>{props.title}</h1>
    </div>
  );
};

export default AdvTitle;
