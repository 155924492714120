import styles from "./header.module.css";
import logoRB from "../../assets/logo.svg";

const Header = () => {

  return (
    <div className={styles.header}>
      <div className={`${styles.containerRB} sitePadding`}>
        <div className={styles.wrapperRB}>
          <div>
            <a href={"https://riesimebyvanie.sk/"}>
              <img src={logoRB} alt="Riešime bývanie" className={styles.imageRB} />
            </a>
          </div>
          <p className={styles.title}>
            <span className={styles.titleBold}>riesime</span>byvanie<span className={styles.titleBold}>.sk</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Header;
