import styles from "./cta.module.css";
import languages from "../../languages/translate";
import {useContext} from 'react';
import SiteContext from "../../store/site-context";

const CTA: React.FC<{matterport: {url: string}}> = (props) => {

  const {userLanguage} = useContext(SiteContext)

  return (
    <div className={styles.container}>
      <h2 className={styles.header}>{languages[userLanguage].viewing.title}</h2>
      <p className={styles.subtitle}>{languages[userLanguage].viewing.description}</p>
      <a href={props.matterport.url} target='_blank' rel="noreferrer" className={styles.link}>{languages[userLanguage].viewing.button}</a>
    </div>
  );
};

export default CTA;
