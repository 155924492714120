import styles from "./appDownload.module.css";
import mockupOne from "../../assets/mobileOne.png";
import mockupTwo from "../../assets/mobileTwo.png";
import appleStore from "../../assets/appleStore.png";
import googlePlay from "../../assets/googlePlay.png";
import appleStoreEN from "../../assets/appleStoreEN.png";
import googlePlayEN from "../../assets/googlePlayEN.png";
import languages from "../../languages/translate";
import { useContext } from "react";
import SiteContext from "../../store/site-context";
import AppVersion from "../../components/appVersion";
import getOS from "../../utils/getOs";

const AppDownload = () => {
  const { userLanguage } = useContext(SiteContext);
  const environment = process.env.REACT_APP_ENV
  return (
    <a
      href={
        getOS() === "Android"
          ? "https://play.google.com/store/apps/details?id=com.rb.rb.prod"
          : "https://apps.apple.com/sk/app/rie%C5%A1ime-b%C3%BDvanie-martin-%C4%8Dapo/id1667153926?l=sk"
      }
      target="_blank"
      rel="noreferrer"
      className={styles.appDownloadLink}
    >
      <div className={styles.app}>
        <div className={styles.containerMobile}>
          <img src={mockupOne} alt="mobileApp" className={styles.imageOne} />
          <img src={mockupTwo} alt="mobileApp" className={styles.imageTwo} />
        </div>
        <div className={styles.containerIcons}>
          <img src={userLanguage === 'en' ? appleStoreEN: appleStore} alt="apple store" className={styles.imageThree} />
          <img src={userLanguage === 'en' ? googlePlayEN : googlePlay} alt="google play store" className={styles.imageFour} />
        </div>
        <p className={styles.appHeader}>{languages[userLanguage].download.title}</p>
        <p className={styles.appText}>{languages[userLanguage].download.description}</p>
        <p className={styles.link}>{languages[userLanguage].download.button}</p>
      </div>
      {environment !== 'production'? <AppVersion />: null }
    </a>
  );
};

export default AppDownload;
