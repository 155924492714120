import styles from "./description.module.css";
import languages from "../../languages/translate";
import {useContext} from 'react';
import SiteContext from "../../store/site-context";

const Description: React.FC<{ description: string }> = (props) => {

  const {userLanguage} = useContext(SiteContext)
  return (
    <div className={styles.description}>
      <h3 className="headerSmall">{languages[userLanguage].description}</h3>
      <p className={styles.text}>{props.description}</p>
    </div>
  );
};

export default Description;
