import Summary from "../modules/main/summary";
import AdvTitle from "../modules/main/title";
import Amenities from "../modules/main/amenities";
import CTA from "../modules/main/cta";
import Description from "../modules/main/description";
import Gallery from "../modules/main/gallery";
import MapComponent from "../modules/main/map";
import Video from "../modules/main/video";
import Header from "../modules/header/header";
import Footer from "../modules/footer/footer";
import useWindowDimensions from "../utils/WindowDimensions";
import { Size } from "../types/enums";
import AppDownload from "../modules/footer/appDownload";
import { useSearchParams } from "react-router-dom";
import useFetch from "react-fetch-hook";
import Hero from "../modules/header/hero";
import ErrorPage from "./error";
import { useContext } from "react";
import SiteContext from "../store/site-context";

const Advertisement = () => {
  const { width } = useWindowDimensions();
  const urlSite = process.env.REACT_APP_URL_HTTP;
  const urlPath = process.env.REACT_APP_URL_PATH;
  const { userLanguage } = useContext(SiteContext);
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  const { isLoading, data, error } = useFetch(`${urlSite}${urlPath}${id}`, {
    headers: {
      "Content-Type": "application/json",
      call: "api",
      version: "v1",
      lang: userLanguage,
    },
  });

  if (isLoading)
    return (
      <div className="loadingScreen">
        <h1 className="loadingHeader">Loading...</h1>
      </div>
    );

  if (error ) return <ErrorPage />;

  return (
    <div >
      <div >
        <div>
          <Header />
        </div>
        <div >
          <div >
            <Hero medias={data.medias} />
            <main className={`main sitePadding`}>
              <div className={"left"}>
                <AdvTitle
                  matterport={data.matterport}
                  title={data.title}
                />
                <div>
                  <Summary
                    price={data.pricing.price}
                    availableFrom={data.pricing.availableFrom}
                    deposit={data.pricing.deposit}
                    minimalRent={data.pricing.minimalRent}
                    roomsInTotal={data.details.roomsInTotal}
                    floor={data.details.floor}
                    numberOfBathrooms={data.details.numberOfBathrooms}
                    maximalOccupancy={data.details.maximalOccupancy}
                    area={data.details.area}
                    petsAllowed={data.details.petsAllowed}
                    rent={data.rent}
                    priceType={data.pricing.priceType}
                    successStory={data.successStory}
                    plot={data.details.plot}
                    advertisementType={data.advertisementType}
                  />
                  {width < Size.desktopBreakpoint ? (
                    <div className="videoMapWrapper">
                      {data.urlYoutube && <Video urlYoutube={data.urlYoutube} />}
                      <MapComponent longitude={data.coordinate.longitude} latitude={data.coordinate.latitude} />
                    </div>
                  ) : null}
                  <Description description={data.description} />
                  <Gallery galleryData={data.medias} />
                  <Amenities amenitiesData={data.amenities} />
                </div>
              </div>

              {width >= Size.desktopBreakpoint ? (
                <div className={` ${data.matterport ? "rightTopLG" : "rightTopSM"} right`}>
                  {data.matterport && <CTA matterport={data.matterport} />}
                  {data.urlYoutube && <Video urlYoutube={data.urlYoutube} />}
                  <MapComponent longitude={data.coordinate.longitude} latitude={data.coordinate.latitude} />
                  <AppDownload />
                </div>
              ) : null}
            </main>
            <Footer
              firstName={data.owner.firstName}
              lastName={data.owner.lastName}
              phone={data.owner.phone}
              email={data.owner.email}
              agentUrl={data.owner.photo}
              role={data.owner.role}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Advertisement;
