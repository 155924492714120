import { createContext } from "react";

type SiteProviderProps = {
    children: React.ReactNode
}

type SiteContextType = {
  userLanguage: string
}

const defaultState = {
  userLanguage: 'sk'
} as SiteContextType

const SiteContext = createContext(defaultState);

export function SiteProvider({ children } : SiteProviderProps) {
  const userLanguage = navigator.language.slice(0, 2) === 'sk' ? 'sk' : 'en';

  return <SiteContext.Provider value={{ userLanguage }}>{children}</SiteContext.Provider>;
}

export default SiteContext;
