import { ITranslation } from "../types/types"

const languages: ITranslation = {
    sk: {
        month: 'mesiac',
        months: {
            sm: 'mesiac',
            md: 'mesiace',
            lg: 'mesiacov'
        },
        priceType: {
            price: 'Cena:',
            priceGuide: 'Cena dohodou:',
            priceUponRequest: 'Cena na vyžiadanie',
            auction: 'Trannsparentná aukcia:'
        },
        availability: 'dostupné od:',
        deposit: 'depozit',
        minimalRent: 'minimálne na:',
        rooms: {
            sm: 'izba',
            md: 'izby',
            lg: 'izieb'
        },
        floor: 'poschodie',
        bath: {
            sm: 'kúpelňa',
            md: 'kúpelne',
            lg: 'kúpelní'
        },
        persons: {
            sm: 'osoba',
            md: 'osoby',
            lg: 'osôb'
        },
        pets: {
            true: 'Zvieratá dovolené',
            false: 'Zvieratá nedovolené'
        },
        description: 'Popis',
        explore: 'Pozrite si túto nehnuteľnosť',
        amenities: 'Výbava',
        broker: 'Realitný expert',
        licence: 'Licencovaný realitný maklér',
        viewing: {
            title: 'Pozri sa dovnútra',
            description: 'Ušetri si čas a prezri si nehnuteľnosť ako 3D Virtuálnu prehliadku.',
            button: 'Zobraziť'
        },
        video: 'Video nehnuteľnosti',
        map: 'Mapa nehnuteľnosti',
        download: {
            title: 'Stiahnite si našu appku',
            description: 'Doprajte si úplne nový zážitok pri vyhľadávaní nehnuteľností.',
            button: 'Stiahnuť'
        },
        error: {
            title: 'Je nám ľúto, ale táto nehnuteľnosť už nie je v ponuke.',
            description: 'Stiahnite si, prosím, našu mobilnú appku a nájdite v nej, čo hľadáte. Alebo navštívte našu domovskú stránku.'
        },
        successStory: 'Úspešne predané',
        successStoryRent: 'Úspešne prenajaté',
        downloadAppTitle: 'Stiahni si radšej appku.',
        downloadAppTextOne: 'Nájdeš v nej všetky inzeráty na prenájom, môžeš si ich zobraziť podľa tvojej polohy + sú tam kontakty na majiteľov a 3D prehliadky.',
        downloadAppTextTwo: 'Všetko v tvojom mobile a zadarmo.',
        tooLateHeading: 'Neskoro',
        tooLateText: 'Inzerát bol stiahnutý z ponuky. V aplikácii môžete nájsť podobné inzeráty.'
    },
    en: {
        month: 'month',
        months: {
            sm: 'month',
            md: 'months',
            lg: 'months'
        },
        priceType: {
            price: 'Price:',
            priceGuide: 'Price guide:',
            priceUponRequest: 'Price upon request',
            auction: 'Auction:'
        },
        availability: 'available from:',
        deposit: 'deposit',
        minimalRent: 'minimal rent:',
        rooms: {
            sm: 'room',
            md: 'rooms',
            lg: 'rooms'
        },
        floor: 'floor',
        bath: {
            sm: 'bath',
            md: 'baths',
            lg: 'baths'
        },
        persons: {
            sm: 'pers.',
            md: 'pers.',
            lg: 'pers.'
        },
        pets: {
            true: 'Pets allowed',
            false: 'No pets'
        },
        description: 'Description',
        explore: 'Explore this property',
        amenities: 'Amenities',
        broker: 'Real Estate Expert contact',
        licence: 'Licenced real estate agent',
        viewing: {
            title: 'Look inside',
            description: 'Save time and view the property as a 3D Virtual Tour.',
            button: 'Display'
        },
        video: 'Property video',
        map: 'Property map',
        download: {
            title: 'Download our app',
            description: 'Treat yourself to a completely new experience when searching for real estate.',
            button: 'Download'
        },
        error: {
            title: 'Sorry, this property is no longer available.',
            description: 'Please download our mobile app and find what you are looking for. Or go to our homepage.'
        },
        successStory: 'Deal closed',
        successStoryRent: 'Deal closed',
        downloadAppTitle: 'Download the app instead.',
        downloadAppTextOne: 'In it you will find all the ads for rent, you can view them according to your location + there are landloard contacts and 3D tours.',
        downloadAppTextTwo: 'All in your mobile and for free.',
        tooLateHeading: 'Too late',
        tooLateText: 'The advertisement has been withdrawn from the offer. You can find similar advertisements in mobile app.'
    }
}

export default languages