import Advertisement from "./pages/advertisement";
import { Routes, Route } from "react-router-dom";
import { SiteProvider } from "./store/site-context";

function App() {
  return (
    <SiteProvider>
      <Routes>
        <Route path="/" element={<Advertisement />} />
        <Route path="/:uid" element={<Advertisement />} />
      </Routes>
    </SiteProvider>
  );
}

export default App;
